<template>
	<div class>
		<goods-list class="list_" :recommends="goodsList" :finished="loadLastText" :isShow="isShow" :goodsTab="3"
			@getMore="getMore" ref="goodsList"></goods-list>
		<!-- 客服组件 -->
		<customer-service></customer-service>
		<!-- 底部菜单 -->
		<tabbar selectedId="2"></tabbar>
	</div>
</template>

<script>
	import {
		getGoodsListsByRecomm
	} from "@/http/api";
	import Tabbar from "@/components/Tabbar";
	import GoodsList from "@/components/goods/GoodsList";
	import CustomerService from "@/views/home/children/CustomerService";

	export default {
		components: {
			GoodsList,
			Tabbar,
			CustomerService
		},
		data() {
			return {
				pages: 1,
				loadLastText: false,
				goodsList: [],
				isShow: false,
				userInfo: {}
			};
		},
		mounted() {
			this.getGoodsListsByRecomm();
		},
		beforeRouteEnter(to, from, next) {
			// 在渲染该组件的对应路由被 confirm 前调用
			// 不！能！获取组件实例 `this`
			// 因为当守卫执行前，组件实例还没被创建
			if (from.name == "ShopGoodsDetails") {
				to.meta.keepAlive = true;
			} else {
				to.meta.keepAlive = false;
			}
			next();
		},
		methods: {
			// 获取推荐商品列表
			async getGoodsListsByRecomm() {
				const res = await getGoodsListsByRecomm({
					pages: this.pages
				});
				this.isShow = true;
				this.$refs.goodsList.changeLoading(false);
				if (res.code == "200") {
					this.goodsList = this.goodsList.concat(res.data);
					if (res.data.length < res.numPage) {
						this.loadLastText = true;
					} else {
						this.loadLastText = false;
					}
				}
			},
			getMore() {
				if (!this.loadLastText) {
					this.pages++;
					this.getGoodsListsByRecomm();
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.list_ {
		margin-bottom: 1.2rem;
		min-height: 100vh;
	}
</style>
